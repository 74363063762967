import React from 'react'
import FormContainer from './FormContainer'
import CommonSevice from './../services/common-service'
import store from 'store2'
import config from '../config'
import { withRouter } from 'react-router-dom'
import { getLangName } from '../services/common-util'
import Loader from '../common/Loader'

class FormView extends React.Component {

  state = {
    commonService: null,
    loading: true,
    form: {},
    translations: {},
    lang: 'en',
    title: '',
    scoreQMapId: null,
    apiFail: false,
    submitting: false,
  }

  handleInput = event => {
    this.setState({ form: event.target.value });
  };

  componentDidMount() {
    this.setState({ loading: true });
    const search = this.props.location.search;
    const token = new URLSearchParams(search).get("o");
    const scoreQMapId = new URLSearchParams(search).get("q");
    const lang = new URLSearchParams(search).get("l") || this.state.lang;
    store.set(config.tokenKey, token);
    const commonService = CommonSevice(token);
    // commonService.removeSensitiveParams();
    commonService.fetchQuestionnaire(scoreQMapId).then(({ data } = {}) => {
      if(data && data.jsonSchema) {
        const langName = getLangName(lang);
        const codes = commonService.extractCode(data.jsonSchema);
        if(data.title) codes.push(data.title);
        commonService.fetchTranslations(codes, langName).then((translations) => {
          if(translations){
            // if(Object.keys(translations).length !== codes.length && lang !== 'en') {
              // commonService.fetchTranslations(codes, 'en').then((translationsEn) => {
              //   this.setState({ form : data.jsonSchema, title: translationsEn[data.title] ? translationsEn[data.title] : data.title, loading: false, lang, translations: translationsEn });
              // })
            // } else {
              this.setState({ form : data.jsonSchema, title: translations[data.title] ? translations[data.title] : data.title, loading: false, lang, translations, scoreQMapId });
            // }
          } else {
            this.setState({ apiFail: true });
          }
        });
      } else {
        this.setState({ apiFail: true });
      }
    }).catch(e => {
      this.setState({ apiFail: true });
    });
  }

  submitHandler = (data) => {
    if (data && data.data && Object.keys(data.data).length > 1 && !this.state.submitting) {
      this.setState({submitting : true }, () => {
        const token = store.get(config.tokenKey);
        const commonService = CommonSevice(token);
        commonService.submitQuestionnaire(this.state.scoreQMapId, data.data).then(({ data }) => {
          if(data && data.id && data.outcomeId) {
            store.remove(config.tokenKey);
            store.session.remove(`${config.submissionKey}_${this.state.scoreQMapId}`);
            window.location = `${config.redirectUrl}?id=${data.id}&success=${data.outcomeId === 1}`;
          } else {
            this.setState({ apiFail: true });
          }
          return true;
        }).catch(e => {
          this.setState({ apiFail: true });
        })
      })
    }
    return false;
  }

  render() {
    return (<div className="App">
      { this.state.apiFail ? <div className="container">
          <div className='row'>
            <div className='col-sm-12 col-md-8 offset-md-2 editor'>
              <h3>Sorry! Your internet connection failed or the token has expired. Contact site admin or try refreshing the page by clicking <span className={"temp-link"} onClick={() => window.location.reload()}>here.</span>
              </h3>
            </div>
          </div>
        </div> : null }
      {this.state.apiFail ? null : <React.Fragment>
        <div className="container">
          <div className='row'>
            <div className='col-sm-12 col-md-8 offset-md-2 editor'>
              {this.state.title ? <header className="App-header">
                <h6>
                  {this.state.title}
                </h6>
              </header> : null }
            </div>
          </div>
        </div>
        <div className="container">
          <div className='row'>
            <div className='col-sm-12 col-md-8 offset-md-2 editor'>
              {this.state.loading ? <Loader/> : (<FormContainer 
              translations={this.state.translations} 
              form={this.state.form} 
              scoreQMapId={this.state.scoreQMapId}
              lang={this.state.lang}
              submitHandler={this.submitHandler}
              />) }
            </div>
          </div>
        </div>
      </React.Fragment>}
      <div style={{width: '100%', height: '500px'}}>
      </div>
    </div>)
  }
}

export default withRouter(FormView);
