import React from 'react'
import { Form } from 'react-formio'
import '../formio.full.min.css'
import store from 'store2'
import config from '../config'

class FormContainer extends React.Component {

  state = {
    form: {},
    options: {
      language: this.props.lang,
      i18n: {
        [this.props.lang]: this.props.translations
      }
    },
    submission: {},
    apiFail: false,
  }

  componentDidMount() {
    let data;
    try {
      data = JSON.parse(store.session.get(`${config.submissionKey}_${this.props.scoreQMapId}`) || {});
      Object.keys(data).forEach(k => {
        if(data[k] === "") delete data[k]
      })
    } catch (error) {
    }
    this.setForm(this.props.form, { data });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.form !== this.props.form) {
      this.setForm(this.props.form)
    }
  }
  
  setForm(form, submission = {}) {
    try {
      this.setState({ form, submission });
    } catch (e) {
      alert('Invalid Form');
    }
  }

  onChange = (e) => {
    const { data } = e;
    store.session.set(`${config.submissionKey}_${this.props.scoreQMapId}`, JSON.stringify(data));
  }

  render() {
    return (<React.Fragment>
      <Form form={this.state.form} options={this.state.options} submission={this.state.submission} onSubmit={this.props.submitHandler} onChange={this.onChange} />
    </React.Fragment>)
  }
}

export default FormContainer;
